<template>
  <div class="ui grid container-2" id="faqs">
    <div class="two column row stackable">
      <div class="column div-1">
        <div class="left">
          <h1><span>F</span>requently <span>A</span>sked <span>Q</span>uestions</h1>
          <p>Get the answers to all your questions right here. For more concerns, just reach out to us below.</p>
        </div>
      </div>
      <div class="column div-2">
        <div class="right">
          <div class="ui vertical accordion menu">
            <div @click="accordion" class="active title">
              <i class="dropdown icon"></i>
              Getting Started
            </div>
            <div class="active content">
              <ul>
                <li>
                <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/gettingstarted', hash: '#gts1'}" replace>
                  How do I register for a TayoCash account</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/gettingstarted', hash: '#gts2' }" replace>
                    Are minors allowed to register an account?</router-link>
                </li>
              </ul>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              Membership
            </div>
            <div class="content">
              <ul>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/membership', hash: '#mbs1' }" replace>
                    What are the different types of Member Accounts of TayoCash?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/membership', hash: '#mbs2' }" replace>
                    What are the features and services available for each type of Member Account?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/membership', hash: '#mbs3' }" replace>
                    What are the wallet limits and requirements for each Member Account type?</router-link>
                </li>
              </ul>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              Verification
            </div>
            <div class="content">
              <ul>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/verification', hash: '#v1'}" replace>
                    Why does my account need to be verified?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/verification', hash: '#v2'}" replace>
                    Steps in Verification</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/verification', hash: '#v3'}" replace>
                    Steps in uploading ID</router-link>
                </li>
              </ul>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              Partner and Merchant
            </div>
            <div class="content">
              <ul>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/partnermerchant', hash: '#pm1'}" replace>
                    What is a TayoCash Partner or Merchant?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/partnermerchant', hash: '#pm2'}" replace>
                    What benefits do I get when I become a TayoCash Partner or Merchant?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/partnermerchant', hash: '#pm3'}" replace>
                    How do I become a TayoCash Partner or Merchant?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/partnermerchant', hash: '#pm4'}" replace>
                    I am currently a TayoCash Partner. Can I become a TayoCash Merchant?</router-link>
                </li>
              </ul>
            </div>

            <div class="title">
              <i class="dropdown icon"></i>
              TayoCash Services
            </div>
            <div class="content">
              <div class="items">
                <h5>Cash In</h5>
                <ul>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_i1'}" replace>
                      How do I put money into my TayoCash Wallet?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_i2'}" replace>
                      How do I Cash In through a TayoCash Partner or Merchant?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_i3'}" replace>
                      How can I make a Cash In through Over-The-Counter (OTC) bank deposit via UnionBank?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_i4'}" replace>
                      How do I Cash In through the Pay Bills feature in my UnionBank app or online banking portal?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_i5'}" replace>
                      How do I Cash In from my UnionBank account linked to my TayoCash account?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_i6'}" replace>
                      I do not see the Cash In amount in my wallet, what should I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_i7'}" replace>
                    I’m still experiencing the same problem or I have other concerns in using the Cash In Service. What do I do?</router-link>
                  </li>
                </ul>
              </div>

              <div class="items">
                <h5>Cash Out</h5>
                <ul>
                  <!-- <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o1'}" replace>How do I Cash Out money from my TayoCash Wallet?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o2'}" replace>How do I Cash Out through a TayoCash Partner or Merchant?</router-link>
                  </li> -->
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o3'}" replace>How to Cash Out?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o4'}" replace>Where can I Cash Out?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o5'}" replace>How much is the Cash Out Fee?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o6'}" replace>How much can I Cash Out?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o7'}" replace>I can’t Cash Out, what do I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o8'}" replace>Can I ask someone to Cash Out on my behalf?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o9'}" replace>I received an SMS confirmation for an unauthorized Cash Out transaction, what should I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#c_o10'}" replace>What kind of ID do I need to bring?</router-link>
                  </li>
                </ul>
              </div>

              <div class="items">
                <h5>Buy Load</h5>
                <ul>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#bl1'}" replace>
                      How do I buy prepaid load and/or gaming credits using my TayoCash Wallet?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#bl2'}" replace>
                      I purchased prepaid load and/or gaming credits but I have entered an incorrect amount. What do I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#bl3'}" replace>
                      I purchased prepaid load and/or gaming credits but I have entered an incorrect mobile number. What do I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#bl4'}" replace>
                      I got charged after buying prepaid load/gaming credits but did not receive the prepaid load in my mobile number. What do I do?</router-link>
                  </li>
                </ul>
              </div>

              <div class="items">
                <h5>Bank Transfer Service</h5>
                <ul>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#bt1'}" replace>
                      How do I transfer funds to banks or other e-wallet accounts?
                    </router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#bt2'}" replace>
                      The funds I transferred were not reflected in the recipient’s bank or e-wallet account, what should I do?
                    </router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#bt3'}" replace>
                      The amount I transferred is incorrect. What should I do?
                    </router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#bt4'}" replace>
                      I transferred funds to an incorrect account. What should I do?
                    </router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#bt5'}" replace>
                      I have other concerns in using the Fund Transfer Service. What should I do?
                    </router-link>
                  </li>
                </ul>
              </div>

              <div class="items">
                <h5>Fund Transfer</h5>
                <ul>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#ft1'}" replace>
                      How do I transfer funds to another TayoCash member?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#ft2'}" replace>
                      How do I Transfer Funds to Other TayoCash Member via Instapay?
                    </router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#ft3'}" replace>
                      Is there a cap or limit to the amount of funds I can transfer?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#ft4'}" replace>
                      I transferred funds to another TayoCash Member more than I intended to. What should I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#ft5'}" replace>
                      I transferred funds to the wrong TayoCash account. What do I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#ft6'}" replace>
                      I’m still experiencing the same problem or I have other concerns with the Fund Transfer Service. What do I do?</router-link>
                  </li>
                </ul>
              </div>

              <div class="items">
                <h5>Pay Bills</h5>
                <ul>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#pb1'}" replace>
                      Where can I see all TayoCash-accredited billers?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#pb2'}" replace>
                      How do I pay bills through TayoCash?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#pb3'}" replace>
                      I paid my bills via TayoCash, but my payment is not posted yet. What should I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#pb4'}" replace>
                      I’m still experiencing the same problem or I have other concerns with the Bills Payment Service. What should I do?</router-link>
                  </li>
                </ul>
              </div>

              <div class="items">
                <h5>Pay QR</h5>
                <ul>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#pqr1'}" replace>
                      What is Pay QR?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#pqr2'}" replace>
                      How do I use the Pay QR PH feature?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#pqr3'}" replace>
                      My Pay QR PH transaction is not going through. What should I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#pqr4'}" replace>
                      I just made a Pay QR PH transaction, the TayoCash Merchant did not receive a confirmation of my QR payment. What should I do?</router-link>
                  </li>
                  <li>
                    <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/services', hash: '#pqr5'}" replace>
                      I have put in an amount more than I intended to pay on my Pay QR PH transaction. What should I do?</router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="title">
              <i class="dropdown icon"></i>
              Complaints
            </div>
            <div class="content">
              <ul>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/complaints', hash: '#cmplt1'}" replace>
                    When and how can I file a complaint?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/complaints', hash: '#cmplt2'}" replace>
                    Where can I find the complaint form and what happens when I file a complaint?</router-link>
                </li>
              </ul>
            </div>

            <div class="title">
              <i class="dropdown icon"></i>
              Transaction Disputes
            </div>
            <div class="content">
              <ul>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/disputes', hash: '#dspt1'}" replace>
                    What is a transaction dispute?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/disputes', hash: '#dspt2'}" replace>
                    I want to file a transaction dispute, what should I do?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/disputes', hash: '#dspt3'}" replace>
                    Where can I find the dispute form?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/disputes', hash: '#dspt4'}" replace>
                    How long does it take for my dispute to get resolved?</router-link>
                </li>
                <li><router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/disputes', hash: '#dspt5'}" replace>
                    Can I talk to someone about my dispute?</router-link>
                </li>
              </ul>
            </div>

            <div class="title">
              <i class="dropdown icon"></i>
              Account Maintenance/ Management
            </div>
            <div class="content">
              <ul>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm1'}" replace>
                    How do I access my account profile?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm2'}" replace>
                    How can I update my profile?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm3'}" replace>
                    What does my badge in my Dashboard indicate?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm4'}" replace>
                    Where can I see my verification status?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm5'}" replace>
                    Where can I view my current and available wallet balance?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm6'}" replace>
                    How do I change my security PIN?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm7'}" replace>
                    I forgot my PIN. How can I reset it?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm8'}" replace>
                    I cannot log in to my account because I am not receiving a One-Time PIN (OTP). What can I do?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm9'}" replace>
                    Where can I view my Transaction History?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm10'}" replace>
                    I have not received the official receipt/acknowledgement receipt for my transaction. How do I request a copy of it?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm11'}" replace>
                    How do I turn off my app notifications?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm12'}" replace>
                    How do I update my phone number?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm13'}" replace>
                    Should I use my phone’s device authentication settings for TayoCash?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/maintenance', hash: '#accmm14'}" replace>
                  Is there a limit to the amount of money my Tayocash wallet can hold?</router-link>
                </li>
              </ul>
            </div>

            <div class="title">
              <i class="dropdown icon"></i>
              Account Deactivation and Termination
            </div>
            <div class="content">
              <ul>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/deactivation', hash: '#accdt1'}" replace>
                    Can my TayoCash account be deactivated?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/deactivation', hash: '#accdt2'}" replace>
                    Can my TayoCash account be terminated?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/deactivation', hash: '#accdt3'}" replace>
                    How do I recover my deactivated account?</router-link>
                </li>
              </ul>
            </div>

            <div class="title">
              <i class="dropdown icon"></i>
              Account Security
            </div>
            <div class="content">
              <ul>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/accountsecurity', hash: '#accsec1'}" replace>
                    How do I make sure that my TayoCash Wallet is safe?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/accountsecurity', hash: '#accsec2'}" replace>
                    I lost my phone/SIM that’s linked to my TayoCash account.  What do I do?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/accountsecurity', hash: '#accsec3'}" replace>
                    I now have a new SIM/mobile number. How do I request to transfer the funds from my old account to my new TayoCash account?</router-link>
                </li>
                <li>
                  <router-link @click.native="removehash" class="h-violet" :to="{ path: '/faq/accountsecurity', hash: '#accsec4'}" replace>
                    I have other concerns regarding my account security. What do I do?</router-link>
                </li>
              </ul>
            </div>

            <div class="title">
              <i class="dropdown icon"></i>
              <p>TayoCash Fees and Charges <label class='ui red empty circular label'></label></p>
            </div>
            <div class="content">
              <ul>
                <li class="red">
                  <router-link @click.native="removehash" class="h-violet red" :to="{ path: '/faq/feesandcharges', hash: '#tcfc1'}" replace>
                    What are the applicable fees and charges in using the TayoCash App and Service?</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'content2',
  methods: {
    accordion () {
      $('.ui.accordion').accordion()
    },

    removehash() {
      this.$nextTick(() => {
        setTimeout(function() {
          window.location.hash = '_'
          history.replaceState(null, null, ' ');
        }, 50)
      })
    }
  },
  mounted () {
    $('.ui.accordion').accordion()
  }
}
</script>

<style scoped lang='scss'>
</style>
